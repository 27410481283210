<template>
    <div class="product-detail">
        <el-page-header @back="$router.go(-1)" content="商品详情页"></el-page-header>
        <!-- 商品信息 -->
        <div class="container f-c">
            <!-- 轮播图 -->
            <div class="carousel f-fr-jc">
                <el-image v-show="proUrlList[proUrlList_index] && proUrlList_index != -1" class="elimg"
                    :src="proUrlList[proUrlList_index]" :preview-src-list="proUrlList" alt=""></el-image>
                <video v-show="videoUrl && proUrlList_index == -1" controls :src="videoUrl" type="video/mp4" width="450px"
                    height="450px" muted ref="upvideo">
                    <source :src="videoUrl" type="video/mp4">
                    您的浏览器不支持 video 标签。
                </video>
                <div class="f-c-b carousel_box">
                    <div class="carousel_item f-w">
                        <!-- <canvas ref="mycanvas" style="display: none"></canvas> -->
                        <!-- <img v-if="videoUrl" :src="videoSrc" alt=""> -->
                        <video class="video" v-show="videoUrl" :src="videoUrl" type="video/mp4" width="80px" height="80px"
                            muted ref="upvideo" @click="proUrlList_index = -1">
                            <!-- <source :src="videoUrl" type="video/mp4">
                            您的浏览器不支持 video 标签。 -->
                        </video>
                        <div class="item" v-for="(item, index) in proUrlList" :key="index">
                            <img :src="item" alt="" @click="proUrlList_index = index"
                                :style="{ 'border': [proUrlList_index == index ? '1px solid #2f9df8' : 'none'] }">
                        </div>
                    </div>
                    <img class="left" src="@/assets/left.png" alt="" @click="reduction()">
                    <img class="right" src="@/assets/right.png" alt="" @click="add()">
                </div>

            </div>
            <!-- 商品信息 -->
            <div class="info" style="display: flex;justify-content: space-between;">
                <div class="basicInfoBox" style="width: 60%;">
                    <!-- 标题 -->
                    <div class="title">
                        <div class="name">{{ productName }}</div>
                    </div>
                    <div class="basicInfo">
                        <div v-for="(item, index) in productType1" :key="index">
                            <div class="f-c" style="width: 100%;"
                                v-if="item.value && (item.title != '商品简介' && item.title != '商品名称' && item.title != '商品主色' && item.title != '颜色名称')">
                                <span class="basicInfo_title">{{ item.title }}：</span>
                                <div v-if="item.title == '商品价格'">
                                    <span style="color: #FA0101;"
                                        :style="{ fontSize: (item.value.split('/')[0] == '暂无报价') ? '20px' : '24px' }">{{
                                            item.value.split('/')[0] }}</span>
                                    <span v-if="item.value.split('/')[1]"> / </span>
                                    <span>{{ item.value.split('/')[1] }}</span>
                                </div>
                                <span class="basicInfo_value" v-else>{{ item.value }}</span>
                            </div>
                        </div>
                        <div class="">
                            <div v-if="rgbValue" class="f-c">
                                <span class="basicInfo_title2">商品主色：</span>
                                <div @click="dialogShowColor = true"
                                    style="height: 40px;width: 70px;border-radius: 5px;cursor: pointer;"
                                    :style="{ 'backgroundColor': rgbValue }"></div>
                            </div>
                            <div v-if="colorStr" class="f-c">
                                <span class="basicInfo_title2">颜色名称：</span>
                                <span class="basicInfo_value2">{{ colorStr }}</span>
                            </div>
                        </div>
                        <div class="f-c">
                            <div class="pageView mr-50"><i class="el-icon-view"></i> 浏览 {{ hitsCount }}
                            </div>
                            <div v-show="pfState == 'Y'" class="collectBtn" @click="handleCollect"
                                v-if="$route.path == '/productDetail'">
                                <img src="@/assets/alreadyCollected.png" alt="">
                                <span>已收藏</span>
                            </div>
                            <div v-show="pfState == 'N'" class="collectBtn" @click="handleCollect"
                                v-if="$route.path == '/productDetail'">
                                <img src="@/assets/heart.png" alt="">
                                <span>收藏商品</span>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 供应商信息 -->
                <div class="supplierInfo" v-if="isshowsupply">
                    <div class="supplierBox f-cl-c-c">
                        <img :src="eimage1" alt="">
                        <div class="name">供应商名称</div>
                        <div class="namevalue">{{ enterpriseName }}</div>
                        <div class="right f-c" @click="toEnterprise()">
                            <span>查看</span>
                            <img src="@/assets/rightSkip.png" alt="">
                        </div>
                    </div>
                    <!-- <div v-show="pfState == 'Y'" class="collectBtn f-c-c" @click="handleCollect"
                        v-if="$route.path == '/productDetail'">
                        <img src="@/assets/alreadyCollected.png" alt="">
                        <span>已收藏</span>
                    </div>
                    <div v-show="pfState == 'N'" class="collectBtn f-c-c" @click="handleCollect"
                        v-if="$route.path == '/productDetail'">
                        <img src="@/assets/heart.png" alt="">
                        <span>收藏商品</span>
                    </div> -->
                    <!-- alreadyCollected -->
                </div>
                <!-- <div class="pageView"><i class="el-icon-view"></i> 浏览 {{ hitsCount }}</div> -->
            </div>
            <!-- 颜色预览 -->
            <el-dialog title="颜色预览" :visible.sync="dialogShowColor" width="710px">
                <div class="color-gb f-c-c" :style="{ background: rgbValue }">
                    <div>RGB：{{ rgbValue }}</div>
                    <img class="copy" src="@/assets/copy.png" alt="" @click="copy()">
                </div>

                <div style="text-align: center;">色彩预览会因终端设备的显示效果产生差异.仅供参考使用</div>
            </el-dialog>
        </div>
        <!-- 商品基本信息 -->
        <div class="productbasicInfo">
            <div class="infoBox">
                <div class="argumentBox">
                    <div class="title">参数信息</div>
                    <div v-if="Productparameter.length != 0" class="argument f-w">
                        <div v-for="(item, index) in Productparameter" :key="index">
                            <div class="argument_item" v-if="item.value">
                                <span class="argument_title">{{ item.title }}：</span>
                                <span class="argument_value">{{ item.value }}</span>
                            </div>

                        </div>
                    </div>
                    <div v-else>未设置</div>
                </div>
                <div class="introTextBox">
                    <div class="title">商品简介</div>
                    <div class="introText">{{ introduction }}</div>
                </div>

            </div>
        </div>
        <!-- 相关商品 -->
        <div class="supplier">
            <div class="supplier-relevant f-c-b">
                <div class="text">相关商品</div>
                <router-link class="f-c check" :to="{
                    name: 'MoreProductList',
                    query: {
                        key: $UrlEncode.encode(
                            JSON.stringify({
                                id: enterpriseId,
                            })
                        ),
                    },
                }">查看更多
                    <i class="el-icon-arrow-right"></i>
                </router-link>
            </div>
            <div class="supplier_box f-w">
                <div class="supplier_item" v-for="(item, index) in tabContainerList" :key="index"
                    @click="toDetailPage(item.productId)">
                    <img :src="item.img1Url" alt="">
                    <div class="name">{{ item.productName }}</div>
                    <div class="f-c-b text">
                        <span class="price">{{ item.unitPrice ? item.valuationCurrency : '' }}
                            {{ item.unitPrice ? item.unitPrice.toFixed(2) : '暂无报价' }}</span>
                        <span class="characters">{{ item.shortageFlag == 'Y' ? '售罄' : '在售'
                        }}</span>
                    </div>
                    <!-- 角标 -->
                    <div class="cornermark">{{ item.productTypeStr }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin'
import { UnitAccount } from "@/utils/attributeValue1Options"
export default {
    name: "ProductDetail",
    // name: "product-detail",
    inject: ['reload'],
    mixins: [UrlDecodeId],
    data() {
        return {
            title: '商品基本信息',
            productType1: [],
            Productparameter: [],
            proUrlList: [],
            proUrlList_index: "",
            rgbValue: "",
            colorStr: "",
            videoUrl: "",
            videoSrc: "",
            enterpriseId: "",
            enterpriseList: [],
            enterPriseImage: "",
            productName: "",
            tabContainerList: [],
            pageSize: 5,
            enterpriseName: '',//供应商名称
            eimage1: '',  //供应商头像
            pfState: '',//该商品是否被收藏
            isCollect: 'N',
            dialogShowColor: false,
            hitsCount: '',
            productPrice: '',
            priceUnit: '',
            introduction: '',  // 简介
            productTypeVal: '', // 商品类型
            isshowsupply: true,
        };
    },
    created() {
        let params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.isshowsupply = params.isshowsupply ?? true;
        this.productImageSearchDetails(this.queryId);
        this.$setStorage('isreload', true);
    },
    watch: {
        // 监听路由是否变化
        $route(to, from) {
            if (from.path == to.path) {
                this.reload();// 刷新当前页面
            }
        },
    },
    mounted() {
        // this.findvideocover()
    },
    methods: {
        // 首页图像搜索列表详情接口
        productImageSearchDetails(productId) {
            let that = this,
                userId = parseInt(this.$store.state.userId), data = {
                    productId,
                    source: "pc",
                    lastModifyUserId: parseInt(userId),
                    token: this.$getStorage("token")
                };
            that.$http.productImageSearchDetails(data).then(res => {
                if (res.code == 200) {
                    let { productType, productName, productNumber, colorStr, unitPrice, valuationCurrency, hitsCount, introduction, videoUrl, proUrlList, enterpriseId, pfState } = res.data,
                        { attributeValue1, attributeValue2, attributeValue3, attributeValue4, attributeValue5, attributeValue6, attributeValue7, attributeValue8, attributeValue9, attributeValue10, attributeValue11, attributeValue12,
                            attributeValue13, attributeValue14, attributeValue15, attributeValue16 } = res.data.productAttribute,
                        { enterpriseName, eimage1 } = res.data.enterpriseInfo,
                        productPrice = unitPrice ? (valuationCurrency + (unitPrice ? parseFloat(unitPrice).toFixed(2) : "")) + attributeValue1 : "暂无报价", //商品价格
                        rgbValue = res.data.rgbValue != "" ? ("#" + res.data.rgbValue) : "";
                    that.rgbValue = rgbValue;
                    that.colorStr = colorStr ?? '';
                    that.introduction = introduction ? introduction : '暂无简介';
                    that.videoUrl = videoUrl ?? '';
                    that.proUrlList_index = videoUrl ? -1 : 0;
                    that.proUrlList = proUrlList ?? '';
                    that.enterpriseId = enterpriseId ?? '';
                    that.enterpriseName = enterpriseName ?? '';
                    that.eimage1 = eimage1 ?? '';
                    that.productName = productName ?? '';
                    that.pfState = pfState ?? '';
                    that.hitsCount = hitsCount ?? '';
                    that.productPrice = productPrice
                    that.priceUnit = attributeValue1 ?? '';
                    that.productTypeVal = UnitAccount.getproductType(productType);
                    switch (productType) {
                        case 1:
                            if (attributeValue12 || attributeValue16 || (attributeValue8 != '未设置' && attributeValue8 != '') || attributeValue7 || attributeValue2 || attributeValue3 || attributeValue14 || attributeValue5 || attributeValue15 || attributeValue9 ||
                                attributeValue10 || attributeValue11) {
                                that.Productparameter = [
                                    {
                                        "title": "平均张幅(最小)",
                                        "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue13 : ''
                                    }, {
                                        "title": "平均张幅(最大)",
                                        "value": attributeValue16 ? attributeValue16 + ` ` + attributeValue13 : ''
                                    }, {
                                        "title": "等级",
                                        "value": attributeValue8 != '未设置' ? attributeValue8 : ''
                                    }, {
                                        "title": "工艺",
                                        "value": attributeValue7 ?? ''
                                    }, {
                                        "title": "材质",
                                        "value": attributeValue2 ?? ''
                                    }, {
                                        "title": "厚度(最小)",
                                        "value": attributeValue3 ? attributeValue3 + ` mm` : ''
                                    }, {
                                        "title": "厚度(最大)",
                                        "value": attributeValue14 ? attributeValue14 + ` mm` : ''
                                    }, {
                                        "title": "硬度(最小)",
                                        "value": attributeValue5 ? attributeValue5 + ` N/mm²` : ''
                                    }, {
                                        "title": "硬度(最大)",
                                        "value": attributeValue15 ? attributeValue15 + ` N/mm²` : ''
                                    }, {
                                        "title": "可定制花纹",
                                        "value": attributeValue9 == "Y" ? "是" : "否"
                                    }, {
                                        "title": "可定制厚度",
                                        "value": attributeValue10 == "Y" ? "是" : "否"
                                    }, {
                                        "title": "可定制工艺",
                                        "value": attributeValue11 == "Y" ? "是" : "否"
                                    },
                                ]
                            }
                            break;
                        case 2:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || (attributeValue3 !== '未设置' && attributeValue3 != '') || (attributeValue4 !== '未设置' && attributeValue4 != '')
                                || (attributeValue5 !== '未设置' && attributeValue5 != '') || attributeValue6 || attributeValue8 || attributeValue9) {
                                that.Productparameter = [
                                    {
                                        "title": "底基类型",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "工艺类型",
                                        "value": attributeValue3 != '未设置' ? attributeValue3 : ''
                                    }, {
                                        "title": "风格类型",
                                        "value": attributeValue4 != '未设置' ? attributeValue4 : ''
                                    }, {
                                        "title": "产品功能",
                                        "value": attributeValue5 != '未设置' ? attributeValue5 : ''
                                    }, {
                                        "title": "厚度",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "张幅(最小)",
                                        "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue10 : ''
                                    }, {
                                        "title": "张幅(最大)",
                                        "value": attributeValue9 ? attributeValue9 + ` ` + attributeValue10 : ''
                                    }
                                ]
                            }
                            break;
                        case 3:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || (attributeValue3 !== '未设置' && attributeValue3 != '') || attributeValue4 || attributeValue5
                                || attributeValue6 || attributeValue12 || attributeValue8 || attributeValue10) {
                                that.Productparameter = [
                                    {
                                        "title": "材质",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "工艺",
                                        "value": attributeValue3 != '未设置' ? attributeValue3 : ''
                                    }, {
                                        "title": "尺寸(长)",
                                        "value": attributeValue4 ? attributeValue4 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "尺寸(宽)",
                                        "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "尺寸(高)",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "内径",
                                        "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "线粗",
                                        "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue9 : ''
                                    }, {
                                        "title": "重量",
                                        "value": attributeValue10 ? attributeValue10 + ` ` + attributeValue11 : ''
                                    }
                                ]
                            }
                            break;
                        case 4:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || (attributeValue3 !== '未设置' && attributeValue3 != '') || (attributeValue4 !== '未设置' && attributeValue4 != '')
                                || attributeValue5 || attributeValue6 || (attributeValue8 !== '未设置' && attributeValue8 != '') || (attributeValue9 !== '未设置' && attributeValue9 != '') || attributeValue10 || attributeValue11 || attributeValue12
                                || attributeValue13 || attributeValue14) {
                                that.Productparameter = [
                                    {
                                        "title": "类型",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "材质",
                                        "value": attributeValue3 !== '未设置' ? attributeValue3 : ''
                                    }, {
                                        "title": "工艺",
                                        "value": attributeValue4 !== '未设置' ? attributeValue4 : ''
                                    }, {
                                        "title": "尺码范围(最小)",
                                        "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "尺码范围(最大)",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "风格",
                                        "value": attributeValue8 !== '未设置' ? attributeValue8 : ''
                                    }, {
                                        "title": "功能",
                                        "value": attributeValue9 !== '未设置' ? attributeValue9 : ''
                                    }, {
                                        "title": "前掌(宽)",
                                        "value": attributeValue10 ? attributeValue10 + ` ` + attributeValue15 : ''
                                    }, {
                                        "title": "前掌(高)",
                                        "value": attributeValue11 ? attributeValue11 + ` ` + attributeValue15 : ''
                                    }, {
                                        "title": "后跟(宽)",
                                        "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue15 : ''
                                    }, {
                                        "title": "后跟(高)",
                                        "value": attributeValue13 ? attributeValue13 + ` ` + attributeValue15 : ''
                                    }, {
                                        "title": "腰位(宽)",
                                        "value": attributeValue14 ? attributeValue14 + ` ` + attributeValue15 : ''
                                    }
                                ]
                            }
                            break;
                        case 5:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || attributeValue3 || attributeValue5 || attributeValue7) {
                                that.Productparameter = [
                                    {
                                        "title": "材质类型",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "厚度",
                                        "value": attributeValue3 ? attributeValue3 + ` ` + attributeValue4 : ''
                                    }, {
                                        "title": "宽度",
                                        "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue6 : ''
                                    }, {
                                        "title": "最大长度",
                                        "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : ''
                                    }
                                ]
                            }
                            break;
                        case 6:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || (attributeValue3 !== '未设置' && attributeValue3 != '') || attributeValue4 || attributeValue6 || attributeValue8) {
                                that.Productparameter = [
                                    {
                                        "title": "材质类型",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "编织类型",
                                        "value": attributeValue3 !== '未设置' ? attributeValue3 : ''
                                    }, {
                                        "title": "厚度",
                                        "value": attributeValue4 ? attributeValue4 + ` ` + attributeValue5 : ''
                                    }, {
                                        "title": "宽度",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : ''
                                    }, {
                                        "title": "最大长度",
                                        "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue9 : ''
                                    }
                                ]
                            }
                            break;
                        case 7:
                            if (attributeValue2 || attributeValue3 || (attributeValue4 !== '未设置' && attributeValue4 != '') || (attributeValue5 !== '未设置' && attributeValue5 != '') || attributeValue6 || attributeValue7) {
                                that.Productparameter = [
                                    {
                                        "title": "面料",
                                        "value": attributeValue2 ? attributeValue2 : ''
                                    }, {
                                        "title": "适用人群",
                                        "value": attributeValue3 ? attributeValue3 : ''
                                    }, {
                                        "title": "风格",
                                        "value": attributeValue4 !== '未设置' ? attributeValue4 : ''
                                    }, {
                                        "title": "功能",
                                        "value": attributeValue5 !== '未设置' ? attributeValue5 : ''
                                    }, {
                                        "title": "尺码范围(最小)",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue8 : ''
                                    }, {
                                        "title": "尺码范围(最大)",
                                        "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : ''
                                    }
                                ]
                            }
                            break;
                        case 8:
                            if ((attributeValue2 !== '未设置' && attributeValue2 != '') || attributeValue3 || attributeValue4 || attributeValue5 || attributeValue6 || attributeValue7 || attributeValue9) {
                                that.Productparameter = [
                                    {
                                        "title": "款式",
                                        "value": attributeValue2 !== '未设置' ? attributeValue2 : ''
                                    }, {
                                        "title": "面料",
                                        "value": attributeValue3 ? attributeValue3 : ''
                                    }, {
                                        "title": "里料",
                                        "value": attributeValue4 ? attributeValue4 : ''
                                    }, {
                                        "title": "规格(长)",
                                        "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue8 : ''
                                    }, {
                                        "title": "规格(宽)",
                                        "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue8 : ''
                                    }, {
                                        "title": "规格(高)",
                                        "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : ''
                                    }, {
                                        "title": "容量",
                                        "value": attributeValue9 ? attributeValue9 + ` ` + attributeValue10 : ''
                                    }
                                ]
                            }
                            break;
                    }
                    that.productType1 = [
                        {
                            "title": "商品类型",
                            "value": UnitAccount.getproductType(productType)
                        },
                        {
                            "title": "商品价格",
                            "value": productPrice ?? ''
                        },
                        {
                            "title": "商品名称",
                            "value": productName ?? ''
                        },
                        {
                            "title": "商品编号",
                            "value": productNumber ?? ''
                        },

                    ];
                    that.openEnterpriseProductList();
                }
            });
        },
        // 首页企业开放商品搜索列表接口
        openEnterpriseProductList() {
            let that = this, data = {
                size: that.pageSize,
                page: 1,
                enterpriseId: that.enterpriseId,
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            };
            that.$http.openEnterpriseProductList(data).then(res => {
                let { records } = res.data;
                records.forEach(item => {
                    item.productTypeStr = UnitAccount.getproductType(item.productType)
                })
                that.tabContainerList = records;
            });
        },
        // 跳转去供应商页面
        toEnterprise() {
            this.$router.push({
                name: 'SupplierInfoIndex',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: this.enterpriseId,
                        type: 'product',
                    }))
                }
            })
        },
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId,
                            isshowsupply: false,
                        }))
                    }
                })
            }
        },
        // 收藏
        handleCollect() {
            let that = this;
            let state = that.pfState == 'Y' ? 'N' : 'Y';
            that.$http.addCancelProductFavorites({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId: that.queryId,
                pfState: state
            }).then(res => {
                if (res.code == 200) {
                    that.pfState = state
                    that.$common.message(res.data, 'success')
                }
            })
        },
        // 复制
        copy() {
            let that = this;
            that.$copyText(this.rgbValue).then(
                function (e) {
                    console.log(e)
                    that.$common.message('复制成功', 'success')
                },
                function (e) {
                    console.log(e)
                    that.$common.message('复制失败', 'error')
                }
            );
        },
        reduction() {
            let that = this,
                { proUrlList_index, proUrlList, videoUrl } = this;

            if (videoUrl) {
                if (proUrlList_index > -1) {
                    that.proUrlList_index = proUrlList_index - 1;
                } else {
                    that.proUrlList_index = proUrlList.length - 1;
                }
            } else {
                if (proUrlList_index > 0) {
                    that.proUrlList_index = proUrlList_index - 1;
                } else {
                    that.proUrlList_index = proUrlList.length - 1;
                }
            }
        },
        add() {
            let that = this,
                { proUrlList_index, proUrlList, videoUrl } = this;

            if (videoUrl) {
                if (proUrlList_index < proUrlList.length - 1) {
                    that.proUrlList_index = proUrlList_index + 1;
                } else {
                    that.proUrlList_index = -1;
                }
            } else {
                if (proUrlList_index < proUrlList.length - 1) {
                    that.proUrlList_index = proUrlList_index + 1;
                } else {
                    that.proUrlList_index = 0;
                }
            }
        },
    },
}
</script>

<style lang="less" scoped>
@import "@/assets/css/mixins.less";


.product-detail {
    margin: auto;

    .container {
        .carousel {
            width: 450px;
            height: 560px;
            margin-left: 40px;

            .elimg {
                width: 450px;
                height: 450px;
                border-radius: 10px;
            }

            .carousel_box {
                width: 100%;
                position: relative;

                img {
                    width: 30px;
                    height: 30px;
                }

                .carousel_item {
                    width: 100%;
                    height: 110px;
                    margin-top: 10px;

                    .video {
                        margin-right: 5px;
                        cursor: pointer;

                    }

                    .item {
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .select {
                        box-shadow: 1px -3px 14px 0px rgba(0, 0, 0, 0.1);
                    }

                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                .left {
                    position: absolute;
                    left: -40px;
                    top: 40px;
                    z-index: 999;
                    cursor: pointer;
                }

                .right {
                    position: absolute;
                    right: -40px;
                    top: 40px;
                    z-index: 999;
                    cursor: pointer;
                }
            }
        }

        .info {
            // border: 1px solid red;
            margin-left: 70px;
            height: 560px;
            width: 900px;
            position: relative;

            .basicInfoBox {

                // 标题
                .title {
                    width: 100%;
                    text-align: left;

                    .name {
                        width: 90%;
                        font-size: 30px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 60px;
                        word-break: break-all;
                    }

                }

                .basicInfo {
                    margin-top: 60px;
                    font-size: 16px;
                    line-height: 60px;
                    width: 100%;

                    .basicInfo_title {
                        color: #999999;
                        font-size: 20px;
                        width: 30%;
                    }

                    .basicInfo_title2 {
                        color: #999999;
                        font-size: 20px;
                        width: 30%;
                    }

                    .basicInfo_value {
                        color: #333333;
                        font-size: 20px;
                        width: 70%;
                        word-break: break-all;
                    }

                    .basicInfo_value2 {
                        // border: 1px solid red;
                        color: #333333;
                        font-size: 20px;
                        width: 70%;
                        word-break: break-all;
                    }

                    .collectBtn {
                        // margin: 60px auto;
                        // margin-top: 10px;
                        // padding: 0 20px;
                        // background: #F8F8F8;
                        // border-radius: 10px;
                        width: 120px;
                        cursor: pointer;

                        img {
                            width: 17px;
                            height: 14px;
                        }

                        span {
                            margin-left: 5px;
                            font-size: 16px;
                            color: #999999;
                        }
                    }
                }
            }

            // 供应商
            .supplierInfo {
                // border: 1px solid red;
                width: 40%;
                height: 100%;
                // background: #F8F8F8;
                // border-radius: 40px;
                // padding: 5px 10px;
                // margin: 0 auto 20px;

                .supplierBox {
                    margin: 0 auto;
                    width: 230px;
                    height: 270px;
                    background: #F8F8F8;
                    border-radius: 40px;

                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        // margin-right: 20px;
                    }

                    .name {
                        color: #999999;
                        font-size: 16px;
                        margin-top: 24px;
                    }

                    .namevalue {
                        color: #000000;
                        font-size: 18px;
                        margin-top: 10px;
                        font-weight: 400;
                    }

                    .right {
                        margin-top: 44px;
                        cursor: pointer;

                        span {
                            color: #0363FA;
                            font-size: 16px;
                            font-weight: 400;
                            margin-right: 10px;
                        }

                        img {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }

                // .collectBtn {
                //     margin: 60px auto;
                //     padding: 20px 70px;
                //     background: #F8F8F8;
                //     border-radius: 10px;
                //     width: 90px;
                //     cursor: pointer;

                //     img {
                //         width: 17px;
                //         height: 14px;
                //     }

                //     span {
                //         margin-left: 5px;
                //         font-size: 16px;
                //         color: #999999;
                //     }
                // }
            }

            .pageView {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                color: #999999;
                font-size: 16px;
                line-height: 40px;
            }
        }

        /deep/ .el-dialog {
            border-radius: 10px;
        }
    }

    .productbasicInfo {
        margin-top: 50px;

        .title {
            font-size: 24px;
            color: #333333;
        }

        .infoBox {
            margin-top: 30px;

            // border: 1px solid red;
            .introTextBox {
                width: 40%;

                .title {
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 60px;
                }

                .introText {
                    // border: 1px solid #409eff;

                    color: #999999;
                    font-size: 16px;
                    line-height: 25px;
                    padding: 0 30px 0 0;
                }
            }


            .argumentBox {
                width: 80%;

                .title {
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 60px;
                }

                .argument {
                    // border: 1px solid #333333;
                    // width: 60%;
                    // margin-top: 20px;
                    font-size: 14px;
                    line-height: 30px;

                    .argument_item {
                        // width: 33.3333%;
                        width: 270px;
                        margin-right: 60px;

                        .argument_title {
                            color: #999999;
                        }

                        .argument_value {
                            color: #333333;
                        }
                    }
                }
            }

        }

    }

    .supplier {
        margin-top: 20px;

        .top {
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 10%);
        }

        .supplier-relevant {
            line-height: 45px;
            margin-top: 50px;

            .text {
                font-size: 24px;
                color: #333333;
            }

            .check {
                display: block;
                width: 20%;
                text-align: right;
                color: #B1B1B1;

                &:hover {
                    color: red;
                }
            }
        }

        .supplier_box {
            .supplier_item {
                background: #F6F8FB;
                border-radius: 10px;
                cursor: pointer;
                position: relative;
                margin-right: 30px;
                width: calc(20% - 30px);

                &:hover {
                    transform: translateY(-3px);
                }

                img {
                    // width: 205px;
                    width: 100%;
                    height: 186px;
                    border-radius: 10px 10px 0 0;
                }

                .name {
                    margin: 10px 0;
                    font-size: 16px;
                    padding-left: 5px;
                    word-break: break-all;
                }

                .text {
                    padding: 0 5px 10px 5px;

                    .price {
                        color: #FF4E36;
                        font-size: 14px;
                    }

                    .characters {
                        color: #999999;
                        font-size: 14px;
                    }
                }

                .cornermark {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    border-radius: 5px;
                    background: #ffffff;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    color: #699EF2;
                    padding: 5px;
                }
            }
        }
    }

    .color-gb {
        width: 290px;
        height: 120px;
        margin: 0 auto 25px;
        text-align: center;
        line-height: 120px;
        color: #ffffff;

        // position: relative;
        .copy {
            right: 0px;
            top: 0px;
            margin-left: 10px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 999999;
        }
    }
}
</style>
